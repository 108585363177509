import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

import Index from "../components/pages/index/Index"
import Intro from "../components/elements/intro"
import kerning from '../data/kerning'
import style from './index.module.scss'
import access from "../data/access"

const calculateHoursTillOpen = (index) => {

  const today = new Date().getDay()
  // console.log(today)

  const activeWeekday = Math.ceil((index+1)/2)

  return today <= activeWeekday ? activeWeekday - today : 7 - today + activeWeekday
}

const IndexPage = ({ data }) => {

  const info = JSON.parse(data.allStoryblokEntry.nodes.filter(node => node.content.match('page__info'))[0].content)
 
  const content = JSON.parse(data.allStoryblokEntry.nodes.filter(node => node.content.match('roseline'))[0].content)

  // return <div></div>
  const artistsInfo = content.artists.map((artist, i) => {
    return {
        ...artist,
        daysTillOpen: calculateHoursTillOpen(i),
        id: i
    }
  })

  const [ state, setState ] = useState({
    isLoaded: false,
    isOpen: false,
    index: false,
    artist: false,
    password: ''
  })

  useEffect(() => {
    if(new Date().getDay() === 0){
      // GIVE ACCESS TO ALL ARTISTS IT IF IS A SUNDAY
      console.log('IT IS SUNDAY –– You have access to all artists!')
      access.password = true
    }

    const listenToKeyDown = (e) => {

      const key = e.key

      const newPassword = state.password + key
      // console.log(e.key, newPassword)

      if(newPassword.match('redirectionberlin')){
        console.log('PASSWORDMATCHES')
        access.password = true
      }

      setState({
        ...state,
        password: newPassword
      })

    }

    window.addEventListener('keypress', listenToKeyDown)

    let timeout;
    if(!state.isLoaded){
      timeout = setTimeout(() => {
        setState({
          ...state,
          isLoaded: true
        })
      }, 1000)
    }

    return () =>  { 
      clearTimeout(timeout) 
      window.removeEventListener('keypress', listenToKeyDown)
    }
  }, [state])

  const setParentIndex = (index) => {

    if(index === false){
      setState({
        ...state,
        artist: false,
        isOpen: false, 
      })

    }else{
      const artist = artistsInfo.find(artist => artist.id === index);
  
      setState({
        ...state,
        artist: artist,
        isOpen: true, 
      })
    }
    // console.log('state', state)
  }

  return(
    <Layout>
      <SEO title="Map" />
      <div className={`${style.index} ${state.isOpen ? style.isOpen : ''}`} >
        <div className={style.wrapper} >
          <Index 
          info={info}
          artistsInfo={artistsInfo}
          setParentIndex={setParentIndex}
          isOpen={state.isOpen}
          index={state.index}
          artist={state.artist}
          mailArtNodes={data.allWordpressPost.nodes}
          wordpressNodes={data.allWordpressPost.nodes}
          // mailArtNodes={[]}
          // wordpressNodes={[]}
          />
        </div>
      </div>
      {!state.isLoaded && <Intro textString={`Redirection Berlin`} kerning={kerning.redirection_berlin}/>}
      <style>{`
      
      .swiper-button-next:after, .swiper-button-prev:after {
        color: white;
      }
      
      `}</style>
    </Layout>
  )
}

export default IndexPage


// Including Wordpress
export const query = graphql`
  query {
    # storyblokEntry(slug: { eq: "artists" }) {
    #   content
    # }
    allStoryblokEntry{
      nodes{
        content
      }
    }
    # allArtistsJson {
    #   nodes {
    #     artist
    #     description
    #     id
    #     title
    #   }
    # }
    allWordpressPost {
      nodes {
        acf {
          format
          media
          media_type
          sender {
            name
          }
          receiver_post {
            ...on WordPressAcf_artist{
              artist {
                name
              }
            }
          }
          media_post {
            ...on WordPressAcf_image{
              image {
                source_url
                media_details {
                  sizes {
                    full {
                      source_url
                    }
                    large {
                      source_url
                    }
                    medium {
                      source_url
                    }
                  }
                }
              }
            }
          }
          subject
          text
          title
          date
        }
      }
    }
  }
`


