import React from "react"

// const circlePos = [
//     'M49,25a25.36,25.36,0,0,1-.35,4.21A24.66,24.66,0,0,1,24.5,50a24.23,24.23,0,0,1-18-8A25.22,25.22,0,0,1,0,25,24.75,24.75,0,0,1,24.5,0a24,24,0,0,1,11,2.64A25.07,25.07,0,0,1,49,25Z',
//     'M49,25c0,1.44-2.42,6.47-6.22,11.71-7,9.67-11.9,14.75-22.36,11.57C13.62,46.23,13.79,42,9.31,37,5.26,32.56.72,22.75.72,16.18A16.73,16.73,0,0,1,17.88,0c10.47,0,13.43,7.25,16,9.9C40.56,16.72,49,15.22,49,25Z'
//   ]
export default ({ className, onClick }) => {
    return(
      <div className={className} >
        {/* <svg viewBox="0 0 50 50" onClick={() => setState({d: circlePos[Math.floor(Math.random() * circlePos.length)]})}> */}
        <svg viewBox="0 0 50 50" >
          <defs>
            <rect id="sa12" x="0" y="0" width="50" height="50" rx="50" ry="50"   />
          </defs>
          <mask id="re8-clip">
            <rect id="bg" x="0" y="0" width="55" height="55" fill="white" />
            <use xlinkHref="#sa12" fill="Black" />
          </mask>
          <rect x="0" y="0" width="55" height="55" fill="white" mask="url(#re8-clip)" onClick={onClick}/>
        </svg>
        <div style={{background: "white", width: "100%", height: "100%"}} />
      </div>
    )
  }
  