import React, { useState } from "react"
import style from './IndexMapNav.module.scss'
import { ButtonLocation, ButtonCamera, ButtonSwitch } from '../../buttons/Buttons'
import Tensorflow from '../../tensorflow/tensorflow'
import Intro from '../../elements/intro'
import access from '../../../data/access'
import Link from 'gatsby-link'
import data from '../../../data/access'
// TESTING PURPOSES!!!
          // const userLocation = [ 13.374522 + 0.005, 52.558085 + 0.005 ]
          // 0: (2) ["13.318936", "52.512901"]
          // 1: (2) ["13.321936", "52.512901"]
          // 2: (2) ["13.3329167", "52.5137778"]
          // 3: (2) ["13.374522", "52.558085"]
          // 4: (2) ["13.398222", "52.515611"]
          // 5: (2) ["13.399792", "52.516428"]
          // 6: (2) ["13.402667", "52.521746"]
          // 7: (2) ["13.408864", "52.519805"]
          // 8: (2) ["13.4110335", "52.5253333"]
          // 9: (2) ["13.4117313", "52.52082"]
          // 10: (2) ["13.4177557", "52.5185556"]
          // 11: (2) ["13.442139", "52.504639"]
          // 12: (2) [13.4197519, 52.5254319]
          // 13: (2) [13.3497563, 52.518697]

const handleLocationError = (err) => {
  switch(err){
    case 1:
        alert("Permission to access Geolocation denied")
        break;
    case 2:
        alert("Position is currently not available")
        break;
    case 3:
        alert("Searching for signal timed out")
        break;
    default:
        alert("Unknown Error")
        break;
  }
}

const IndexMapNav = ({ updateParentState, handleViewSwitch, handleOnClick, mailView }) => {

  const [ state, setState ] = useState({
    accessGranted: false,
    scanAccess: false,
    locationAccess: false,
  })

  const handleWebcamAccess = () => {

    if(state.scanAccess){
        // stopWebcam()
        setState({
            ...state, 
            scanAccess: false 
        })
        return false
    }else{
      setState({
        ...state,
        scanAccess: true
      })
    }
  }

  const handleLocationAccess = () => {

    // getPosition or watchPosition
    navigator.geolocation.watchPosition(
        (position) => {
          // console.log(position)

          

          // REAL USER LOCATION
          const userLocation = [ position.coords.longitude, position.coords.latitude ]

          data.userLocation = userLocation

          updateParentState({
              // IS OFFSET ON BRAVE DESKTOP BROWSER
              userLocation,
              // userLocation: data.userLocation
          })
          setState({
              ...state, 
              locationAccess: true 
          })
        },
        (error) => {
          handleLocationError(error.code)
          // updateParentState({ userLocation: [ 13.41053, 52.52437 ] }) // SETTING FAKE LOCATION!
        },
        { timeout: 4000, enableHighAccuracy: true }
    );
  }

  const accessGranted = () => {

    access.webcam = true

    setState({ 
      ...state, 
      accessGranted: true,
      scanAccess: false
    })
  }

  // TODO: Add a Notice that you have access now!!

  return(
    <>
      <header>
        <Link to="/" >
            <div className={style.button} onClick={() => { handleOnClick(false) }}/>
        </Link>

        <ButtonCamera
        onClick={handleWebcamAccess}
        className={style.webcamAccess}
        active={state.scanAccess}
        guide="Activate Camera to Scan your Ticket"
        />

        <ButtonLocation
        onClick={handleLocationAccess}
        className={style.locationAccess}
        active={state.locationAccess}
        guide="Allow Localization to get Access"
        // color={'red'}
        />

        <ButtonSwitch
        className={style.switch}
        onClick={handleViewSwitch}
        active={mailView}
        />
        
      </header>
      {
        state.scanAccess ?
        <>
          <Intro textString="Camera" kerning={[0,0,4,4,-1,-1,0,0,0,0,0,0]}/>
          <div
          className={style.webcamWrapper}
          onClick={handleWebcamAccess}
          >
            <div className={style.webcamVideo}>
              {!state.accessGranted ? <Tensorflow accessGranted={accessGranted}/> : false}
            </div>
          </div>
        </>
        : false
      }
    </> 
  )
}

export default IndexMapNav
