import React from 'react';
import style from './IndexArtistTicker.module.scss';

const IndexArtistTicker = ({ text, isHighlighted }) => {

  let string = []; // create an empty array with length 45
  
  for(let i = 0; i < 10; i++)  string += `${text} +++ `

  return(
    <div className={style.ticker}>
      <div className={`${style.tickerWrapper} ${isHighlighted ? style.isHighlighted : ''}`}>
          <span>
          {string}
          </span>
          <span>
          {string}
          </span>
      </div>
    </div>
  )
}

export default IndexArtistTicker