import React from 'react'
import { Link } from 'gatsby'
import setToWeekday from '../../../helpers/setToWeekday'
import style from './IndexMapTimetable.module.scss'


const IndexMapTimetableItem = ({ id, name, isHoveredIndex, todaysArtistIndex, currentIndex, handleMouseOver, onClick }) => {

    const day = setToWeekday(null, id+1)
    const openToday = currentIndex+1 === todaysArtistIndex
    const isHovered = isHoveredIndex === id

    // if(!handleMouseOver) return false

    return(
        <li key={id}>
            <div
            className={`${style.artist} ${isHovered ? style.hovered : ''}`} 
            // onMouseEnter={() => handleMouseOver(id) }
            // onMouseLeave={() => handleMouseOver(false) }
            >
                <div className={`${style.artistGrid} ${openToday ? style.open : ""}`}>
                    <div>

                        <span>{currentIndex % 2 ? '' : day}</span>
                    </div>
                    <div>
                        <span
                        className={style.artistSpan}
                        onMouseEnter={() => handleMouseOver(id) }
                        onMouseLeave={() => handleMouseOver(false) }
                        onClick={() => { onClick(id) }}>
                            {name}
                        </span> 
                    </div>
                </div>
            </div>
        </li>
    )
}


{/* <li key={artist.id}>
<div
className={`${style.artist} ${isHovered ? style.hovered : ''}`} 
onMouseEnter={() => handleMouseOver(artist.id)}
onMouseLeave={() => handleMouseOver(false)}
>
    <div className={`${style.artistGrid} ${openToday ? style.open : ""}`}>
        <span>{i % 2 ? '' : day}</span>
        <span onClick={() => { onClick(artist.id) }}>{artist.name}</span> 
    </div>
</div>
</li> */}

const IndexMapTimetable = ({ artistsInfo, onClick, handleMouseOver, isHoveredIndex }) => {

    // GET TODAYS ARTIST INDEX!
    const weekday = new Date().getDay() * 2
    const time = new Date().getHours() >= 12 ? 0 : 1
    const todaysArtistIndex = weekday - time
    // console.log(todaysArtistIndex, weekday, time)

    const artists = artistsInfo.slice(0, 12).sort((a, b) => a.daysTillOpen - b.daysTillOpen)

    let currentIndex = -1

    return(
        <>
            <div className={style.openToday}>
                <h3>
                    {
                        artists || weekday === 0 ?
                        <span>Currently on Display:</span>
                        :
                        <span>We are closed on Saturday</span>
                    }
                </h3>
                {
                    artists.filter(artist => artist.daysTillOpen === 0).map((artist, i) => {
                        
                        currentIndex++
                        
                        return(

                        <Link key={i} to={`/artists/${artist.slug}`}>
                            <div>{artist.name}</div>
                        </Link>
                    )})
                }
            {
                weekday === 0 ?
                <div>
                {/* <Link href={`/artists/${artist.slug}`}> */}
                    <p>All Artists</p>
                {/* </Link> */}
                </div>
                : false
            }
            </div>
            <div className={style.info}>
                
                <div>Upcoming</div>
                <br/>
                <ul>
                {
                    artists.filter(artist => artist.daysTillOpen !== 0).map((artist, i) => {

                        currentIndex++

                        return(
                            <IndexMapTimetableItem
                            key={i}
                            id={artist.id}
                            name={artist.name}
                            currentIndex={i}
                            todaysArtistIndex={todaysArtistIndex}
                            isHoveredIndex={isHoveredIndex}
                            handleMouseOver={handleMouseOver}
                            onClick={onClick}
                            />
                        )
                    })
                }
                {/* <br/> */}
                <li>
                    <div className={style.artist}>
                            <div className={style.artistGrid}>
                                <span className={style.day}>Sun</span>
                                <span>All Artists</span>
                            </div>
                    </div>
                </li>
                </ul>
                {/* <br/> */}
                <ul>
                    <IndexMapTimetableItem
                    id={currentIndex+1}
                    // name="Isaac Chong Wai"
                    name={<><span>12.6.21 </span><span>2pm </span><span>Isaac Chong Wai</span></>}
                    currentIndex={currentIndex+1}
                    todaysArtistIndex={todaysArtistIndex}
                    isHoveredIndex={isHoveredIndex}
                    handleMouseOver={handleMouseOver}
                    onClick={onClick}
                    />
                    {/* <IndexMapTimetableItem
                    id={currentIndex+2}
                    // name="Ruohan Wang"
                    name={<><span>8.5.21 </span><span>2pm </span><span>Ruohan Wang</span></>}
                    currentIndex={currentIndex+2}
                    todaysArtistIndex={todaysArtistIndex}
                    isHoveredIndex={isHoveredIndex}
                    handleMouseOver={handleMouseOver}
                    onClick={onClick}
                    /> */}
                </ul>
            </div>
            
        </>
    )
}

export default IndexMapTimetable