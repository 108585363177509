import React, { useState } from "react"
import style from './IndexMap.module.scss'
import access from '../../../data/access'

import { Marker, Map } from 'react-mapbox-gl';


const mapsArray = [
    {
        name: "dark",
        style: "mapbox://styles/burrowmaps/ckcbr7v5d0t501io6ojlidpy9", 
    },
    {
        name: "Style 2",
        style: "mapbox://styles/burrowmaps/ckh6953l90o5z1ameyzvnspna", 
    },
    {
        name: "Style 3",
        style: "mapbox://styles/burrowmaps/ckh69kt5u118s19k6gp23jkv3", 
    },
    {
        name: "Style 4",
        style: "mapbox://styles/burrowmaps/ckh6bn1s512px19kh1xtzokmy", 
    },
]

const MyMap = Map({

    // accessToken: JSON.parse(process.env.MAPBOX_ACCESSTOKEN),
    accessToken: process.env.GATSBY_MAPBOX_API_TOKEN ,
    // accessToken,
    // dragPan: false,
    dragRotate: false,
    scrollZoom: false,
});

var easingFunctions = {
    // start slow and gradually increase speed
    easeInCubic: function (t) {
    return t * t * t;
    },
    // start fast with a long, slow wind-down
    easeOutQuint: function (t) {
    return 1 - Math.pow(1 - t, 5);
    },
    // slow start and finish with fast middle
    easeInOutCirc: function (t) {
    return t < 0.5
    ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
    : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2;
    },
    // fast start with a "bounce" at the end
    easeOutBounce: function (t) {
        var n1 = 7.5625;
        var d1 = 2.75;
        
        if (t < 1 / d1) {
        return n1 * t * t;
        } else if (t < 2 / d1) {
        return n1 * (t -= 1.5 / d1) * t + 0.75;
        } else if (t < 2.5 / d1) {
        return n1 * (t -= 2.25 / d1) * t + 0.9375;
        } else {
        return n1 * (t -= 2.625 / d1) * t + 0.984375;
        }
    }
};


const MapGl = ({ parentState, onClick, isOpen, handleMouseOver, isHoveredIndex }) => {

    // console.log(parentState.locations.map(location => location.camera.center ))

    const [ state, setState] = useState({
        mapLoaded: false,
        userLocation: access.userLocation
    })

    const handleMapLoad = () => {

        setState({
            ...state,
            mapLoaded: true,
            userLocation: false
        })
    }

    return(
        <div >
            <div className={style.mapInfo}>
                <div className={style.mapInfoWrapper}>
                    <div>{parentState.title}</div>
                    <div>{parentState.artist}</div>
                    <div>{parentState.description}</div>
                </div>
            </div>     
            <div className={`${style.background} ${state.mapLoaded ? style.loaded : ""}`}>
                
                <MyMap
                data-id={state.userLocation}
                style={mapsArray[0].style}
                containerStyle={{
                    height: '100vh',
                    width: '100vw',
                }}
                zoom={parentState.zoom}
                center={parentState.center}
                pitch={parentState.pitch}
                // interactive={false}
                dragPan={false}
                dragRotate={false}
                scrollZoom={false}
                flyToOptions={{
                    speed: 0.3, // make the flying slow
                    curve: 1.8, // change the speed at which it zooms out
                    easing: easingFunctions.easeInOutCirc,
                    offset: isOpen ? [-200, 0] : [0,0],
                }}
                onStyleLoad={handleMapLoad}
                >
                    {
                        parentState.locations.slice(0, 12).map(( location, i ) => (
                            location.type === 'circle' &&
                            <CustomMarker 
                            key={i}
                            i={i}
                            location={location}
                            handleMouseOver={handleMouseOver}
                            onClick={onClick}
                            index={parentState.index}
                            isHoveredIndex={isHoveredIndex}
                            />
                        ))
                    }
                    <CustomMarkerTriangle
                    label="a"
                    currentNumber={12}
                    index={parentState.index}
                    // coordinates={[13.4197519,52.5254319]}
                    // coordinates={[13.417834,52.522454]} // aus dem Dokument
                    coordinates={[13.4160395,52.5223587]} // von giu
                    isHoveredIndex={isHoveredIndex}
                    handleMouseOver={handleMouseOver}
                    onClick={onClick}
                    />
                    {/* <CustomMarkerTriangle
                    label="II"
                    currentNumber={13}
                    index={parentState.index}
                    coordinates={[13.3497563,52.518697]}
                    isHoveredIndex={isHoveredIndex}
                    handleMouseOver={handleMouseOver}
                    onClick={onClick}
                    /> */}
                    {
                        parentState.userLocation ? 
                        <Marker coordinates={parentState.userLocation} anchor="bottom" >   
                            <div className={`${style.marker} ${style.markerUser}`} />
                            {/* <div className={`${style.markerUserRing}`} /> */}
                            <div className={`${style.markerScribble}`}>
                                <svg className={style.markerUser1} viewBox="0 0 300 200" width="300" height="200">
                                    <path d="M177.93,47.81c-33.18-.45-141.48,7.62-141.48,117.71" transform="translate(-5.76 -15.67)"/>
                                    <path d="M62.46,149.38C45.87,167.77,38,176.74,33.31,179S6.18,121.8,7.3,123.6s31.17,63.45,32.52,61,10.31-39.91,11.88-39.46" transform="translate(-5.76 -15.67)"/>
                                    <path d="M200.74,44.63c4.8-6.3,8.68-8.88,13.07-9.15-4.45,2.54-15.47,17.71-4.26,20s4.93-17.3,4.93-17.3c4.48,3.85,11.66,1.61,16.14-2.65-2,9.19-1.12,16.82,6.28,17.94s10.54-20,10.54-20c-.22,1.12-2,34.22,7.62,22.15" transform="translate(-5.76 -15.67)"/>
                                    <path d="M177.93,20.82c8.52,9.87,13.46,17.13,17.27,12.87S212.69,14,207.3,17.77c-2.52,1.79-11.5,9.1-15,17.13C188,44.88,190,63.28,190,63.28" transform="translate(-5.76 -15.67)"/>
                                    <path d="M137.64,79c-7.95-5.3-29.74,2.06-18.84,13.25s24.44-19.44,24.44-19.44c-3.24,14.43-5.45,27.39,2.5,25.18s10.6-28.71,10.6-28.71l-2.65,26.5s4.36-24.29,10.8-24.29,23.21,14.13,28.07,6.18-18.11-9.47-18.11,6.09,8,16.49,16.79,10.06" transform="translate(-5.76 -15.67)"/>
                                    <path d="M219.47,63.28c-4.08,6.67-18.73,51.46-18.73,51.46s5.8-31.48,18.73-34.8c6.76-1.73,8.83,2.47,2.59,26.24,5.92-2.54,27.76-26.61,17.4-26.24s-9.63,31.1-2.59,28.51,14.8-27,14.8-27l-5.4,24.76s7.63-28.83,9.48-24.76,20,2.22,20-2.59-15.55-4.44-16.66,17,12.22,16.29,16.66,8.52" transform="translate(-5.76 -15.67)"/>
                                </svg>
                            </div>
                        </Marker>
                        : false
                    }
                </MyMap> || false
            </div>
            <style>{`
                .mapboxgl-control-container{
                    display: none;
                }
            `}</style>
        </div>
    )
}

export default MapGl


const CustomMarkerTriangle = ({ coordinates, label, currentNumber, index, isHoveredIndex, handleMouseOver, onClick }) => {

    const userLocations = {
        lng: 0,
        lat: 0,
    }

    const locations = {
        lng: 0,
        lat: 0,
    }

    return(
        <Marker
        coordinates={coordinates}
        anchor="bottom"
        className={currentNumber === index ? style.markerActive : ""}
        >
            <div 
            data-id={263}
            // className={`${style.markerTriangle}`}
            className={`
                ${style.markerTriangle} 
                ${currentNumber === isHoveredIndex ? style.hovered : ""}
            `}
            onMouseEnter={() => {
                handleMouseOver(currentNumber)
            }}
            onMouseLeave={() => handleMouseOver(false)}
            onClick={() => onClick(currentNumber)}
            >
                <svg viewBox="0 0 30 26" width="30" style={{ position: 'absolute'}}>
                    <path 
                    d="M 15 1 L 1 26 L 29 26 L 15 1"
                    // stroke="red"
                    strokeWidth="0"
                    fill={currentNumber === isHoveredIndex ? "red" : 'white'}
                    />
                </svg>
                <div>{label}</div>
            </div>
        </Marker>
    )
}


const CustomMarker = ({ location, index, isHoveredIndex, handleMouseOver, onClick }) => {
    
    const currentNumber = location.id

    // const userLocations = {
    //     lng: 0,
    //     lat: 0,
    // }

    // const locations = {
    //     lng: 0,
    //     lat: 0,
    // }

    // const isAccessible = currentNumber === access.location[currentNumber](userLocations, location)

    return (
        <Marker
        key={currentNumber}
        coordinates={location.camera.center}
        anchor="bottom"
        className={currentNumber === index ? style.markerActive : ""}
        >
            <div 
            onMouseEnter={() => handleMouseOver(location.id)}
            onMouseLeave={() => handleMouseOver(false)}
            data-id={currentNumber}
            className={`
                ${style.marker} 
                ${currentNumber === isHoveredIndex ? style.hovered : ""}
            `}
            onClick={() => onClick(currentNumber)}
            >
                {currentNumber+1}
            </div>
        </Marker>
    )
}