import React, { useState } from "react"
import Map from "./IndexMap"
import IndexMapTimetable from "./IndexMapTimetable"
import IndexMapNav from "./IndexMapNav"
import style from './Index.module.scss'
import IndexArtistPopUpInfo from './IndexArtistPopUpInfo'
import IndexMailArt from './IndexMailArt'
import IndexInfo from "./IndexInfo"

const zoomOut = 12
const zoomIn = 12.2

const berlinCoords = {
    lng: 13.41,
    lat: 52.52, 
    center: [ 13.375, 52.54 ],
    zoom: [zoomOut],
}


const generateLocations = (artists) => artists.filter(artist => artist.name).map(( location, i ) => {

    const newLocation = {
        ...location,
        type: "circle"
    }


    let center = [0,0]
    if(location.latitude && location.longitude){
        center = [location.longitude, location.latitude]
    }
    newLocation.camera = {
        center: center,
        zoom: zoomIn,
        pitch: [50],
    }
    return newLocation
})

const Index = ({
    artistsInfo,
    isOpen,
    setParentIndex,
    artist,
    mailArtNodes,
    wordpressNodes,
    info
}) => {


    const [ state, setState ] = useState({
        index: false,
        center: berlinCoords.center,
        pitch: [0],
        zoom: [zoomOut],
        locations: [
            ...generateLocations(artistsInfo),
            {        
                // Performance 1
                camera: {
                    center: [13.4197519,52.5254319],
                    zoom: zoomIn,
                    pitch: [50],
                },
                type: "triangle"
            },
            {        
                // Performance 1
                camera: {
                    center: [13.3497563,52.518697],
                    zoom: zoomIn,
                    pitch: [50],
                    type: "triangle"
                }
            }
        ],
        userLocation: false ,
        scanAccess: false,
        mapView: true,
        blur: false,
    })  

    const [ isInfoOpen, setIsInfoOpen ] = useState(false)
    const [ isHoveredIndex, setIsHoveredIndex ] = useState(false)

    const handleOnClick = (index) => {

        setParentIndex(index)

        const setIndex = Number.isInteger(index) && 'isOpen';

        setState({
            ...state,
            index: index,
            // GET RIGHT CENTER!!!
            center: setIndex ? state.locations[index].camera.center : berlinCoords.center,
            pitch: setIndex ? [50] : [0],
            zoom: [zoomIn]
        })
    }

    const handleViewSwitch = () => {

        if(state.index){

            handleOnClick(false)

            // setTimeout(() => { 
                        
                setState({
                    ...state,
                    mapView: !state.mapView,
                    pitch: !state.mapView ? [0] : [45],
                    zoom: !state.mapView ? [zoomOut] : [11],
                    blur: state.mapView,
                })
                
            // }, 1200)

        }else{
            setState({
                ...state,
                mapView: !state.mapView,
                pitch: !state.mapView ? [0] : [45],
                zoom: !state.mapView ? [zoomOut] : [11],
                blur: state.mapView,
            })
        }
    }

    const handleInfoOpen = (boolean) => {

        if(state.index && boolean){

            handleOnClick(false)

            setTimeout(() => { setIsInfoOpen(boolean) }, 1200)

        }else{
            setIsInfoOpen(boolean)
        }
    }

    const updateState = (newState) => { setState({ ...state, ...newState }) }

    const handleMouseOver = (index) => {
        setIsHoveredIndex(index)
    }

    return(
        <div >
            <div className={style.viewMap} style={{
            filter: state.blur ? 'blur(100px)' : '',
            opacity: state.blur ? 0 : 1,
            pointerEvents: state.blur ? 'none' : 'unset',
            }}>
                <Map
                parentState={state}
                userLocation={state.userLocation}
                onClick={handleOnClick}
                isOpen={isOpen}
                handleMouseOver={handleMouseOver}
                isHoveredIndex={isHoveredIndex}
                />
                <div className={`${style.skyfade} ${isOpen ? style.active : ''}`}></div>
                <div className={`${style.radialfade}`}></div>

                {/* Timetable Top Right & Bottom Left */}
                <IndexMapTimetable
                parentState={state}
                artistsInfo={artistsInfo}
                onClick={handleOnClick}
                handleMouseOver={handleMouseOver}
                isHoveredIndex={isHoveredIndex}
                />

                <IndexArtistPopUpInfo 
                onClick={() => { handleOnClick(false) }}
                artist={artist}
                isOpen={isOpen}
                index={state.index}
                />

                <IndexInfo
                info={info}
                nodes={wordpressNodes}
                onClick={() => {}}
                isInfoOpen={isInfoOpen}
                setIsInfoOpen={() => { handleInfoOpen(!isInfoOpen) }}
                handleViewSwitch={handleViewSwitch}
                />
            </div>
            <div className={style.viewMap} >
                <IndexMailArt
                isBlur={state.blur}
                mailArtNodes={mailArtNodes}
                />
            </div>
            {!isInfoOpen && <IndexMapNav
            updateParentState={updateState}
            handleViewSwitch={handleViewSwitch}
            handleOnClick={handleOnClick}
            mailView={!state.blur}
            />}
        </div>
    )
}


export default Index
