import React, { useEffect, useState } from 'react'
import style from './IndexArtistPopUpInfo.module.scss'
import Link from 'gatsby-link'
import IndexArtistTicker from './IndexArtistTicker'
import IndexGuidePortal from './IndexGuidePortal'
import { Portal } from 'react-portal';
import RichText from '../../layout/richText'
import access from '../../../data/access'
// import Image from '../../elements/image'

const IndexArtistPopUpInfo = ({ artist, isOpen, onClick }) => {

    // TODO – fix fade ou when closing info popup
    
    const [ showGuide, setShowGuide ] = useState(false);
    const [ currentArtist, setCurrentArtist ] = useState(false)
    // const [ showContent, setShowContent ] = useState(false)

    const openGuide = () => {
        setShowGuide(true)
        // setShowContent(true)
    }

    const closeGuide = () => {
        setShowGuide(false)
        // setTimeout(() => {
        //     setShowContent(true)
        // }, 300)
    }

    const accessWebcam = access.webcam;
    const accessPassword = access.password;

    const accessLocation = access.location[0](
        access.userLocation, // userLocation
        { lng: currentArtist.longitude, lat: currentArtist.latitude }
    ); 

    useEffect(() => {

        if(artist === false){
            setTimeout(() => {
                setCurrentArtist(artist)
            }, 900)
        }else{
            setCurrentArtist(artist)
        }
    }, [artist])

    // console.log('accessLocation', accessLocation)
    // console.log('userLocation', access.userLocation)
    // console.log('timeslot', access.timeslot[id], id)

    return(
        <>

            {showGuide && <Portal>
                <IndexGuidePortal onClick={closeGuide} text={`
            `}/>
            </Portal>}
            <div className={`${style.aside} ${isOpen ? style.isOpen : ''}`}>
                {artist && <div className={style.close} onClick={onClick} >
                    <svg viewBox="0 0 30 30" width="30" height="30">
                        <line strokeWidth="2" stroke="white" x1="1" y1="1" x2="29" y2="29" />
                        <line strokeWidth="2" stroke="white" x1="1" y1="29" x2="29" y2="0" />
                    </svg>
                </div>}
                <div className={style.asideBackground} onClick={onClick} />
                <div className={style.asideContent}>
                    {artist ? 
                    <IndexArtistTicker text={artist.daysTillOpen ? `${artist.daysTillOpen} more Day${artist.daysTillOpen > 1 ? 's' : ''} till Open`: `Is Live Now`} isHighlighted={artist.daysTillOpen === 0} />
                    :
                    <IndexArtistTicker text={`Open on see Date`} isHighlighted={false} />
                    }
                    {
                        currentArtist && currentArtist.image.filename ?
                        <div className={style.asideImage}>
                            {
                                currentArtist.image.filename.match(/jpg|jpeg|webp|png/ig) ?
                                <img src={currentArtist.image.filename} />
                                :
                                <video src={currentArtist.image.filename} autoPlay={true} muted loop={true} />
                            }
                        </div>
                        :
                        <div className={style.asideImage} />
                    }
                    { currentArtist &&
                        <div className={style.asideContentWrapper}>
                            { (accessWebcam || accessLocation || accessPassword) && 
                                <div className={style.linkToExhibition}>
                                    <Link to={`/artists/${currentArtist.slug}`} >
                                        <div className={style.link}>{`Visit Exhibtion`}</div>
                                    </Link>
                                </div>
                            }
                            <div dangerouslySetInnerHTML={{__html: currentArtist.title}} />
                            <div dangerouslySetInnerHTML={{__html: currentArtist.name}} />
                            <br/>
                            <RichText text={currentArtist.description} className={style.asideText}/>
                            <br/>
                            <br/>
                            <RichText text={currentArtist.text} className={style.asideText}/>
                            <br/>
                            <div className={style.linkWrapper}>
                            
                                <br/>
                                <br/>

                                {currentArtist.daysTillOpen === 0 ? 
                                <Link to={`/artists/${currentArtist.slug}`} >
                                    <div className={style.link}>{`Go to Artist`}</div>
                                </Link>
                                :
                                <>
                                    <div>{`Artist will be available in ${currentArtist.daysTillOpen > 1 ? `${currentArtist.daysTillOpen} Days` : 'Tomorrow'}`}</div>
                                    <br/>
                                    <div className={style.link} onClick={openGuide}>{`More Info`}</div>
                                </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}


// const Asset = () => {


//     return(

//     )
// }


export default IndexArtistPopUpInfo