import React, { useState, useEffect } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/swiper.scss'

import style from './IndexMailArt.module.scss'
import IndexMailArtItem from './IndexMailArtItem'


const params = {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  spaceBetween: 30,
  slidesPerView: 5,
  direction: 'horizontal',
  freeMode: true,
  loop: true,
  loopedSlides: 8,
}

const IndexMailArt = ({ isBlur, mailArtNodes }) => {


  let data = []


  const images = []
  
  mailArtNodes.forEach((node) => {

    node.acf.media_post && node.acf.media_post.forEach((item, i) => {
  
          data.push({
  
              x: Math.random() * 100 - 50,
              y: Math.random() * 60 - 30,
              scale: 1 + Math.random() * 1 
          })
  
          images.push({
              image: item.image,
              data: node,
          })
      })  
  })


  const [ transformations ] = useState(data)

  useEffect(() => {}, [transformations])

  return(
    <div className={style.viewMailArt} style={{
      filter: !isBlur ? 'blur(100px)' : '',
      opacity: !isBlur ? 0 : 1,
      pointerEvents: !isBlur ? 'none' : 'unset',
    }}>
        <Swiper {...params}>
            {
                images.map((item, i) => {

                    return(
                        <div key={i}>
                            {isBlur  && <IndexMailArtItem item={item} transformation={transformations[i]}/>}
                        </div>
                    )
                })
            }
        </Swiper>
    </div>
  )
}

export default IndexMailArt


