import React, { useState, useEffect } from 'react'
import style from './IndexMailArt.module.scss'

const SlideItem = ({ item, transformation }) => {

  const [ state, setState ] = useState({ isLoaded: false })

  useEffect(() => {

      let timeout;

      if(!state.isLoaded){
          console.log('delayed')
          timeout = setTimeout(() => { setState({ isLoaded: true}) }, 900)
      }

      return () => {
          if(timeout) clearTimeout(timeout)
      }
  })

  return(
      <div className={style.item} >
         <div
          style={{ 
              position: 'relative',
              // top: state.isLoaded ? 0 : -300,
              transition: '1s',
              // background: state.isLoaded ? 'red' : 'blue',
              transform: `translate(-${transformation.x}px, ${transformation.y}vh) scale(${state.isLoaded ? transformation.scale : 0})` 
          }}
          >
              {item.image && <img src={item.image.source_url} />}
          </div>
      </div>
  )
}

export default SlideItem