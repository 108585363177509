import React, { useEffect, useRef } from "react"
import * as tmImage from '@teachablemachine/image';
import './tensorflow.scss'

const MODEL_URL = 'assets/tenserflow/model.json';
const METADATA_URL = 'assets/tenserflow/metadata.json';


const Tenserflow = ({ accessGranted }) => {

    let videoContainer = useRef()
    let labelContainer = useRef()
    let loop = null
    let webcam = null
    // const [ state, setState ] = useState({
    //     predictions: [],
    // })

    useEffect( () => {

        (async function go(){
            await init()
            await loop()
        })()

        return function cleanup(){
            console.log("cleanup")
            if(loop) cancelAnimationFrame(loop)
            loop = null
            if(webcam) webcam.stop();
        }
    }, [])

    let model;
    // let maxPredictions;

    // Load the image model and setup the webcam
    // async function init() {
    const init = async () => {
        console.log("tensorflow init")
        // TFIMAGE VERSION
        model = await tmImage.load(MODEL_URL, METADATA_URL);
        // maxPredictions = model.getTotalClasses();
        
        // Convenience function to setup a webcam
        const flip = true; // whether to flip the webcam
        webcam = new tmImage.Webcam(300, 300, flip); // width, height, flip
        await webcam.setup(); // request access to the webcam
        await webcam.play();
        // console.log("tensorflow webcam is playing:", webcam)
        if(typeof window !== `undefined` && loop) requestAnimationFrame(loop);

        videoContainer.current.appendChild(webcam.canvas)
    }

    // async function loop() {
    loop = async () => {
        webcam.update(); // update the webcam frame
        // TFIMAGE
        await predict();
        // console.log("tensorflow awaiting predict")7
        if(typeof window !== `undefined` && loop){
            requestAnimationFrame(loop);
        }
    }

    // run the webcam image through the image model
    // async function predict() {
    const predict = async () => {

        const prediction = await model.predict(webcam.canvas);

        if(prediction[0].probability > 0.999){
            console.log('access granted: ', prediction[0].probability)
            cancelAnimationFrame(loop)
            accessGranted()
        }

        if(typeof window !== `undefined` && loop){
            setTimeout(cancelAnimationFrame(loop), 10000)
        }
        
    }


    return(
        <>
            <div className="tenserflow-example">
                {/* <div className="predictions">
                    {
                        state.predictions.map((prediction, i) => {
                            
                            return( 
                                <div key={i}>
                                    {prediction.className + ": " + prediction.probability.toFixed(2)}
                                </div>
                            )
                        })
                    }
                </div> */}
                <div id="label-container" ref={labelContainer} />
                <div className="screenvideo" ref={videoContainer}  />
                <div>Loading</div>
            </div>
        </>
    )
}

export default Tenserflow