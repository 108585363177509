import React, { useRef } from "react"
import style from "./IndexInfo.module.scss"
import SvgCircle from '../../sections/index__circle'
import { ButtonInfo } from '../../buttons/Buttons'
import RichText from "../../layout/richText"
import Image from "../../elements/image"
// import InfoVIdeo from "/assets/video/Drohne.mov"
// import InfoVIdeo from "../../../../static/assets/video/Drohne.mov"
import InfoVIdeoMp4 from "./Redirection_Berlin_web.mp4"
import InfoVIdeoWebm from "./Redirection_Berlin_web.webm"
import { Link } from "gatsby"
import ImageLogo from "../../elements/imageLogo"



const Info = ({ isInfoOpen, setIsInfoOpen, info, handleViewSwitch }) => {

    const handleMenu = () => {

        if(!isInfoOpen){

            setIsInfoOpen(!isInfoOpen)
    
            setTimeout(() => {
                if(ref.current){  
                    ref.current.classList.add(style.show)
                    ref.current.scrollTop = 0
                }
            }, 10)

        }else{
            if(ref.current)  ref.current.classList.remove(style.show)
    
            setTimeout(() => {
                setIsInfoOpen(!isInfoOpen)
            }, 1500)
        }
    }

    const ref = useRef()

    // TODO add instagram!

    const goToMailArt = () => {
        // console.log('Link to Mail Art Page')
        // setIsInfoOpen(false)
        handleMenu()
        setTimeout(() => {

            handleViewSwitch()
        }, 1000)
    }

    // console.log(info)

    return(
        <>  
            <div className={style.buttonWrapper}>
                <ButtonInfo onClick={handleMenu} active={isInfoOpen}/>
            </div>
            <div className={style.info} ref={ref}>
                <div className={style.page__wrapper}>


                    <div className={style.background} onClick={handleMenu} />
                    <div id="intro" className={style.wrapper__intro}>
                        {/* <RichText text={info.info} /> */}
                        <p>
                            Redirection Berlin is a public art project probing the many-faceted concept and understanding of contemporary public space. 
                            Initiated as a mail 
                            {/* TODO: Link to Mailart */}
                            <i onClick={goToMailArt}> art exchange </i> 
                            the project materialized into a spatially distributed digital showroom that can be visited onsite at any time, 
                            and remotely from home during scheduled days of the week.
                            <br/>
                        </p>
                        <p>
                            Redirection (Nachsendeauftrag) Berlin untersucht die vielfältigen Aspekte und Erscheinungsformen des öffentlichen Raums in Form einer über die Stadt verteilten digitalen Ausstellung, 
                            die jederzeit vor Ort und an bestimmten Wochentagen auch von zu Hause aus besucht werden kann.
                            <br/>
                        </p>
                    </div>
                    <div className={style.wrapper__circle1} >
                        <div />
                        <SvgCircle className={style.wrapper__details__circle} onClick={handleMenu}  />
                        <div />
                    </div>

                    <a href={`mailto:${info.order_email}?subject=${info.order_subject}&body=${info.order_content}`}>
                        <div className={style.orderButton}>
                            <div dangerouslySetInnerHTML={{__html: info.order_text}} />
                            
                        </div>
                    </a>
                    
                    <div className={style.wrapper__details}>
                    
                        <div />
                        <div className={style.wrapper__details__left}>
                            <p>
                                Each of the distributed works<NumberSymbol />
                                is associated with a corresponding location chosen for its historical, socioeconomic, environmental or architectural relevance.
                                Art, as an essential aspect of public life, demands our physical presence: The project’s hybrid format encourages the viewer to bring their smart devices outside for a full experience of the exhibition 
                                <ArrowSymbol />. 
                                To get full access to all works from home 
                                <a href={`mailto:${info.order_email}?subject=${info.order_subject}&body=${info.order_content}`}>
                                order a map with a scan code</a>  <ScanSymbol/> or type in «redirectionberlin» on your keyboard.

                                Some excerpts from the preceeding mail art exchange can be found <i onClick={goToMailArt}> here </i><MailArtSymbol/>.
                                <br/>
                            </p>
                                {/* <br/><br/> */}
                            <p>
                                Jedes der über die Stadt verteilten Kunstwerke<NumberSymbol />ist mit einem entsprechenden Ort verbunden, der aufgrund seiner historischen,  oszioökonomischen, ökologischen oder architektonischen Relevanz ausgewählt wurde.

                                Als ein wesentlicher Aspekt des öffentlichen Lebens kommt Kunst nicht ohne unsere körperliche Präsenz aus: Wer sich mit einem Smart Device direkt an die durch Geo-Koordinaten festgelegten Orte in der Stadt begibt, kann jedes Werk zu jeder Zeit sehen
                                <ArrowSymbol />. 
                                Um vollen Zugang zu allen Werken von zuhause aus zu erhalten, <a href={`mailto:${info.order_email}?subject=${info.order_subject}&body=${info.order_content}`}>bestellen Sie</a> einen Stadtplan mit Scan-Code 
                                <ScanSymbol/>oder geben Sie «redirectionberlin» auf Ihrer Tastatur ein.

                                Einige Beispiele aus dem vorangegangenen Mail Art Austausch finden Sie <i onClick={goToMailArt}> hier </i><MailArtSymbol/>.
                                <br/>
                            </p>
                        </div>
                        <div className={style.wrapper__details__right}>
                            <div />
                            <SvgCircle className={style.wrapper__details__circle} onClick={handleMenu}  />
                            <div />
                        </div>
                        <div />
                    </div>


                    <div className={style.list}>

                        <div className={style.rowTitle} dangerouslySetInnerHTML={{__html: `Timeline`}} />
                        {
                            info.list
                            .map((row, i) => (
                                <div key={i} className={style.row}>
                                    <div>{row.title}</div>
                                    {/* <div className={row.check ? style.strikeThrough : ''}>{row.text}</div> */}
                                    <div className={style.strikeThrough}>{row.text}</div>
                                </div>
                            ))
                        }
                    </div>

                    <div className={style.video}>
                        <video controls>
                            <source src={InfoVIdeoWebm} type="video/webm"/>
                            <source src={InfoVIdeoMp4} type="video/mp4"/>
                            {/* <source src={`/assets/video/Drohne.mov`} type="video/mov"/> */}
                        </video>
                        {/* <video controls>
                            <source src={`/assets/video/Drohne.mov`} type="video/mov"/>
                        </video>
                        <video controls>
                            <source src={info.video.filename} type="video/webm"/>
                        </video> */}
                    </div>
                    
                    <div className={style.artists} >
                        {/* <RichText text={info.artists} /> */}
                        <p>
                            Participating artists / teilnehmende Künstler*innen<br/><br/>

                            Caroline Böttcher, Isaac Chong Wai, Anke Dyes, Eva Funk, Eiko Grimberg, J&K,
                            
                            Heike Kabisch, Christin Kaiser, Katharina Kiebacher, Alexandra Leykauf,

                            Katharina Lüdicke, Eric Meier, Roseline Rannoch, Julius C Schreiner, 
                            
                            Sonya Schönberger & Nele Güntheroth, Helin Ulas, Ruohan Wang, Julia Weißenberg, Paul Wiersbinski
                            
                            {/* Nachsendeauftrag / Redirection Berlin  */}
                            
                            {/* is a public art project that actively interacts with the concept of public space. This is how it started,
                            
                            originally planned as a group exhibition at Haus der Statistik, in the centre of Berlin. Then came the pandemic. 
                            
                            We soon found ourselves trapped in private homes, mourning for the loss of public life.<br/>

                            Redirection on instagram */}
                            <br/>
                            <br/>
                            <br/>

                            <a href="https://www.instagram.com/redirection_berlin/" target="_blank" linktype="url"><span >Redirection on instagram</span></a>
                            <br/>
                        </p>
                    </div>

                    <div className={style.footer} >
                        <div>
                            {/* <RichText text={info.footer} /> */}
                            <p>
                                Nachsendeauftrag / Redirectionn Berlin <br/>
                                is a project by / ist ein Projekt von Tsao Yidi & Anna Voswinckel<br/>
                                Projektmanagement: Sarah Veith<br/>
                                Graphic design & Web Development: Burrow, Berlin<br/>
                                Video documentation: Yakin Si<br/>
                                {/* Funded by / Gefördert durch die Senatsverwaltung für Kultur und Europa und NEUSTART KULTUR Programm<br/> */}
                                Funded by / Gefördert durch die Senatsverwaltung für Kultur und Europa und durch die Beauftragte der Bundesregierung<br/>
                                für Kultur und Medien im Programm NEUSTART KULTUR Modul D – <i>Digitale Vermittlungsformate</i>
                            </p>
                            <br/>
                            <br/>
                            <br/>
                            <div className={style.logos}>
                            {
                                info.logos.map((logo, i) => {
                                    
                                    // console.log(logo)

                                    // const image = <Image key={logo.filename} src={logo.filename} />

                                    const imageLink = logo.name || false

                                    if(imageLink){
                                        return(
                                            <div key={i}>
                                                <a href={imageLink} target="_blank">
                                                    <ImageLogo key={logo.filename} src={logo.filename} />
                                                </a>
                                            </div>
                                        )

                                    }else{
                                        return(
                                        <div key={i}>
                                            <ImageLogo key={logo.filename} src={logo.filename} />
                                        </div>
                                        )

                                    }
})
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Info












const NumberSymbol = () => (
    <span>
        &thinsp;
        <svg style={{ transform: 'translateY(2px)' }} viewBox="0 0 12 12" width="12" height="12">
            <circle cx="6" cy="6" stroke="black" r="5" fill="transparent" strokwWidth="1"/>
            <line x1="4" y1="4" x2="8" y2="8" stroke="black" strokwWidth="1" />
            <line x1="8" y1="4" x2="4" y2="8" stroke="black" strokwWidth="1" />
        </svg>
        &thinsp;
    </span>
)

const ArrowSymbol = () => (
    <span>&thinsp;
        <svg height="12" width="12" viewBox="0 0 12 12">
            <path stroke-width="1" fill="none" stroke="black" d="
                M 10 1,
                L 2 7,
                L 5 7,
                L 6 11,
                L 10 1,
            "></path>
        </svg>
    </span>
)

const ScanSymbol = () => (
    <span>
        &thinsp;
        <svg style={{ transform: 'translateY(2px)' }} height="12" width="12" viewBox="0 0 12 12">
            <path stroke-width="1" fill="none" stroke="black" d="
                M 5 1,
                L 1 1,
                L 1 5,
                M 1 7,
                L 1 11,
                L 5 11,
                M 7 11,
                L 11 11,
                L 11 7,
                M 11 5,
                L 11 1,
                L 7 1,
            "></path>
        </svg>
        &thinsp;
    </span>
)

const MailArtSymbol = () => (
    <span>
       <svg height="12" width="12" viewBox="0 0 12 12">
           <path stroke-width="1" fill="none"  stroke="black" d="
                M 3 5,
                L 1 11,
                L 11 11,
                L 9 5,
                z,
            " />
    </svg>
    </span>
)